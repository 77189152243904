import { useQuery } from '@tanstack/react-query';
import { getCustomerTeamId, getIsAuthenticated } from 'features/customer/store/selectors';
import { httpGetTeamUsageHistory } from 'services/backofficeIntegration/http/endpoints/teamMembers/httpGetTeamUsageHistory';
import { useAppSelector } from 'store/hooks';

export function useGetTeamUsageHistory() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const teamId = useAppSelector(getCustomerTeamId);

  return useQuery({
    enabled: isAuthenticated && !!teamId,
    queryKey: httpGetTeamUsageHistory.makeQueryKey(teamId || 0),
    queryFn: () => httpGetTeamUsageHistory.callEndpoint(teamId || 0)
  });
}
