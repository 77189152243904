import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { SurveyAnswersDto } from 'services/backofficeIntegration/http/dtos/SurveyAnswersDto';
import { getSurveyAnswers } from 'services/backofficeIntegration/http/endpoints/surveyQuestions/getSurveyAnswers';
import { useAppSelector } from 'store/hooks';

type Options = UseQueryOptions<
  SurveyAnswersDto,
  unknown,
  SurveyAnswersDto,
  ReturnType<typeof getSurveyAnswers['makeQueryKey']>
>;

export const useSurveyAnswers = (options?: Options) => {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  return useQuery({
    enabled: isAuthenticated,
    ...(options ?? {}),
    queryKey: getSurveyAnswers.makeQueryKey(),
    queryFn: () => getSurveyAnswers.callEndpoint()
  });
};
