import { useQuery } from '@tanstack/react-query';
import { getIsAuthenticated } from 'features/customer/store/selectors';
import { makeWordsUsageQueryKeyPrefix } from 'features/wordsUsage/invalidateWordsUsageQueries';
import CustomerAPI from 'services/api/customer';
import { useAppSelector } from 'store/hooks';

export function useGetWordsCurrentUsage() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);

  return useQuery({
    enabled: isAuthenticated,
    queryKey: [...makeWordsUsageQueryKeyPrefix(), 'current'],
    queryFn: () => CustomerAPI.getWordsCurrentUsage()
  });
}
